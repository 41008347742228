import "../components/Header.css";
import ApplePodcastsLogo from "../images/apple_podcasts_logo.svg";
import SpotifyLogo from "../images/spotify_logo.svg";
import GooglePodcastsLogo from "../images/google_podcasts_logo.svg";
import YoutubeLogo from "../images/youtube_logo.svg";
import AudibleLogo from "../images/audible_logo.svg";
import AmazonMusicLogo from "../images/amazon_music_logo.svg";
import SocialLink from "./SocialLink";

const socialLinksData = [
  {
    id: "apple",
    href: "https://podcasts.apple.com/us/podcast/soul-sistas-sleep-meditations-guided-meditations-for/id1553764633?mt=2&app=podcast&ls=1",
    ariaLabel: "Listen on Apple Podcasts",
    alt: "Apple Podcasts",
    src: ApplePodcastsLogo,
  },
  {
    id: "spotify",
    href: "https://open.spotify.com/show/5SBIm3G1FZ4YjDCiuDqeyz",
    ariaLabel: "Listen on Spotify",
    alt: "Spotify",
    src: SpotifyLogo,
  },
  {
    id: "google",
    href: "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80Y2JiNDk0MC9wb2RjYXN0L3Jzcw",
    ariaLabel: "Listen on Google Podcasts",
    alt: "Google Podcasts",
    src: GooglePodcastsLogo,
  },
  {
    id: "youtube",
    href: "",
    ariaLabel: "Listen on Youtube",
    alt: "Youtube",
    src: YoutubeLogo,
  },
  {
    id: "audible",
    href: "https://www.audible.com/pd/Soul-Sistas-Sleep-Meditations-Guided-Meditations-for-Black-Women-Podcast/B09PJ3XMX2",
    ariaLabel: "Listen on Audible",
    alt: "Audible",
    src: AudibleLogo,
  },
  {
    id: "amazon",
    href: "https://music.amazon.com/podcasts/d63a1d0c-4815-4ee6-af13-82408d5a7e5c/soul-sistas-sleep-meditations---guided-meditations-for-black-women",
    ariaLabel: "Listen on Amazon Music",
    alt: "Amazon Music",
    src: AmazonMusicLogo,
  },
];
const Header = () => {
  return (
    <div className="Header-container">
      <div className="Header-content">
        <div className="Header-social-container">
          <nav className="Header-social-links-nav">
            {socialLinksData.map((link, index) => (
              <SocialLink
                key={index}
                href={link.href}
                ariaLabel={link.ariaLabel}
                alt={link.alt}
                src={link.src}
              />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
