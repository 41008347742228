import React from "react";

const SocialLink = ({ key, href, ariaLabel, alt, src }) => {
  return (
    <a key={key} href={href} aria-label={ariaLabel}>
      <img className="Header-social-logo" src={src} alt={alt} />
    </a>
  );
};

export default SocialLink;
